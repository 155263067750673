import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default {

    state: {
        menus: [],
        asideMenus: [],
        loadRoute: false,
        metadatas: {}
    },
    getters: {
        metadata : (state)=>((metaName)=>{
            return state.metadatas[metaName];
        })
    },
    mutations: {
        setMenus(state, menus){
            state.menus = menus;
        },
        setAsideMenus(state, menus){
            state.asideMenus = menus;
        },
        setLoadRoute(state, loadRoute){
            state.loadRoute = loadRoute;
        },
        setMetadatas(state, menus){
            menus.forEach(menu=>{
                if(menu.routeName){
                    let metaName = menu.routeName.replaceAll('.', '_')
                    try{
                        const metadata = JSON.parse(menu.metadata)
                        state.metadatas[metaName] = metadata
                    }catch (e) {

                    }
                }
            })
        }
    },
    actions: {
        setMenus(content, menus){
            content.commit('setMenus', menus);
        },
        setAsidemenus(content, menus) {
            content.commit('setAsidemenus', menus)
        },
        logOut(content){
            content.commit('setMenus', []);
            content.commit('setAsideMenus', []);
            content.commit('setLoadRoute', false);
            localStorage.removeItem("dscsToken");
            localStorage.removeItem("dscsVerifyPwd");
        },
    },


}