import Vue from 'vue'
import moment from "moment";
import {Search_Type, Validator_Type,Validator_Type_Detail} from './constants'

Date.prototype.toISOString = function(){
    return moment(this).format('YYYY-MM-DD HH:mm:ss')
}
Vue.mixin({
    /*tags success,info,warning,danger*/
    methods:{
        g_getUserType(user_type,color){
            let map = {
                '__': ['未注册','danger'],
                '0': ['管理方','primary'],
                '1': ['设备方','success']
            };
            let index = ('' + user_type).trim();
            if(!map[index]) index = '__';
            return color?map[index][1]:map[index][0];
        },
        g_getUserStatus(user_status,color){
            let map = {
                '__': ['异常','danger'],
                '0': ['启用','primary'],
                '1': ['停用','info'],
            };
            let index = ('' + user_status).trim();
            if(!map[index]) index = '__';
            return color?map[index][1]:map[index][0];
        },
        g_getPage(page, limit){
            return{
                _page: page,
                _limit: limit
            }
        },
        g_getRoleStatus(role_status,color){
            let map = {
                '__': ['异常','danger'],
                '0': ['启用','primary'],
                '1': ['停用','info'],
            };
            let index = ('' + role_status).trim();
            if(!map[index]) index = '__';
            return color?map[index][1]:map[index][0];
        },
        g_getDynamicValue(key, item, color){
            let value = '';
            if(item.props){
                for(var prop of item.props){
                    if(key === prop[item.p1]){
                        value = prop[item.p2];
                    }
                }
            }
            if(color) return value?'primary':'danger';
            else return value?value:key;
        },
        g_getInitPass(){
            let pass = this.$store.getters.initPass;
            if(!pass){
                this.$axios.get('/sys/user/initPass').then(res=>{
                    pass = res.data;
                    this.$store.commit("setInitPass", pass);
                })
            }
            return pass;
        },
        g_getProvinceList(){
            let provinceList = this.$store.getters.provinceList;
            if(!provinceList){
                this.$axios.get('/resource/province/list').then(res=>{
                    provinceList = res.data;
                    this.$store.commit("setProvinceList", provinceList);
                })
            }
            return provinceList;
        },
        g_getUser(){
            let user = this.$store.getters.user;
            if(!user){
                this.$axios.get('/sys/user/self').then(res=>{
                    user = res.data;
                    this.$store.commit("setUser", user);
                })
            }
            return user;
        },
        g_getShenpianCode(cb){
            let code = this.$store.getters.shenpianCode;
            if(!code){
                this.$axios.get('/resource/shenpiancode').then(res=>{
                    code = res.data;
                    this.$store.commit("setShenpianCode", code);
                    if(cb){
                        cb(code)
                    }
                })
            }else{
                if(cb){
                    cb(code)
                }
            }
            return code;
        },
        g_getMenuTagColor(data){
            if(data.id > 10000) return "danger";
            if(data.status !== 0) return "info";
            return data.routeComponent?'success':'primary';
        },
        g_deepClone(object){
            return JSON.parse(JSON.stringify(object));
        },
        g_getCommonStatus(status, color){
            let map = {
                '__': ['异常','danger'],
                '0': ['停用','info'],
                '1': ['启用', 'blue'],
            };
            let index = ('' + status).trim();
            if(!map[index]) index = '__';
            return color?map[index][1]:map[index][0];
        },
        g_getEquipCerStatus(status, color){
            let map = {
                '__': ['异常','danger'],
                '0': ['未注册','primary'],
                '1': ['已注册', 'blue'],
                '2': ['已撤销', 'info'],
                '9': ['已禁用', 'info']
            };
            let index = ('' + status).trim();
            if(!map[index]) index = '__';
            return color?map[index][1]:map[index][0];
        },
        g_getEquipDisableStatus(status, color){
            let map = {
                '__': ['异常','danger'],
                '0': ['停用','info'],
                '1': ['启用', 'blue'],
            };
            let index = ('' + status).trim();
            if(!map[index]) index = '__';
            return color?map[index][1]:map[index][0];
        },
        g_getEquipCerStatusArray(){
            return [0,1,2,9]
        },
        g_getEquipModelStatus(status, color){
            let map = {
                '__': ['异常','danger'],
                '0': ['启用','primary'],
                '9': ['停用', 'info']
            };
            let index = ('' + status).trim();
            if(!map[index]) index = '__';
            return color?map[index][1]:map[index][0];
        },
        g_getFilmStatus(status, color){
            let map = {
                '__': ['异常','danger'],
                '0': ['停用','info'],
                '1': ['启用', 'blue'],
            };
            let index = ('' + status).trim();
            if(!map[index]) index = '__';
            return color?map[index][1]:map[index][0];
        },
        g_getDkdmStatus(status, color){
            let map = {
                '__': ['异常','danger'],
                '0': ['停用','info'],
                '1': ['启用', 'blue'],
            };
            let index = ('' + status).trim();
            if(!map[index]) index = '__';
            return color?map[index][1]:map[index][0];
        },
        //版本状态0已导入1已验签2已上传3已加速4已上架8可用（已同步）9停用
        g_getFilmVersionStatus(status, color){
            let map = {
                '__': ['异常','danger'],
                '0': ['已导入','success'],
                '1': ['已验签', 'success'],
                '2': ['已上传', 'success'],
                '3': ['已加速', 'success'],
                '4': ['已上架', 'success'],
                '8': ['启用', 'success'],
                '9': ['停用', 'info'],
            };
            let index = ('' + status).trim();
            if(!map[index]) index = '__';
            return color?map[index][1]:map[index][0];
        },
        g_notImplemented(){
            this.$message.warning("功能还未开放");
        },
        g_getVendorStatus(status, color){
            let map = {
                '__': ['异常','danger'],
                '0': ['停用','info'],
                '1': ['启用', 'blue'],
            };
            let index = ('' + status).trim();
            if(!map[index]) index = '__';
            return color?map[index][1]:map[index][0];
        },
        g_getRequestBaseUrl(){
            return this.$axios.defaults.baseURL;
        },
        g_getProvinceCityName(provinceId, cityId){
            let result = '';
            let provinceList = this.g_getProvinceList()
            if(provinceList){
                let province = provinceList.filter(province=>province.id===provinceId)[0]
                if(province){
                    result = province.provinceName
                    if(province.cityList){
                        let city = province.cityList.filter(city=>city.id===cityId)[0]
                        if(city){
                            result = result + city.city
                        }
                    }
                }
            }
            return result
        },
        g_loadTableItems(metadata, all){
            const metas = metadata.metas
            const tableItems = metadata.tableOptions.items
            const metaIds = metas.filter(meta=>meta.supportTable).map(meta=>meta.id)
            const selectValidIds = tableItems.map(item=>item.id).filter(id=>metaIds.includes(id))
            const selectItems = selectValidIds.map(id=>{
                const meta = metas.find(meta=>meta.id===id)
                const item = tableItems.find(item=>item.id===id)
                return {...meta,...item}
            })
            if(!all){
                return selectItems
            }else{
                const leftMetas = metas.filter(meta=>selectValidIds.indexOf(meta.id)===-1 && meta.supportTable)
                const leftItems = leftMetas.map(meta=>({...meta}))
                return selectItems.concat(leftItems)
            }
        },
        g_loadSearchItems(metadata, all){
            const metas = metadata.metas
            const selectItems = metadata.searchOptions.items.map(item=>{
                if(item.id<1000){
                    const meta = metas.find(meta=>meta.id===item.id && meta.supportSearch)
                    if(meta){
                        return {...meta, ...item}
                    }else{
                        return null
                    }
                }else{
                    return {...item}
                }
            }).filter(item=>item!=null)
            if(!all){
                return selectItems
            }else{
                const selectIds = selectItems.map(item=>item.id)
                const leftMetas = metas.filter(meta=>selectIds.indexOf(meta.id)===-1 && meta.supportSearch)
                const leftItems = leftMetas.map(meta=>({...meta}))
                return selectItems.concat(leftItems)
            }
        },
        g_loadFormItems(metadata, all){
            const metas = metadata.metas
            const formItems = metadata.formOptions.items
            const metaIds = metas.filter(meta=>meta.supportForm).map(meta=>meta.id)
            const selectValidIds = formItems.map(item=>item.id).filter(id=>metaIds.includes(id))
            const selectItems = selectValidIds.map(id=>{
                const meta = metas.find(meta=>meta.id===id)
                const item = formItems.find(item=>item.id===id)
                return {...meta,...item}
            })
            if(!all){
                return selectItems
            }else{
                const leftMetas = metas.filter(meta=>selectValidIds.indexOf(meta.id)===-1 && meta.supportForm)
                const leftItems = leftMetas.map(meta=>({...meta}))
                return selectItems.concat(leftItems)
            }
        },
        g_loadDetailItems(metadata, all){
            const metas = metadata.metas
            const selectItems = metadata.detailOptions.items.map(item=>{
                if(item.id<1000){
                    const meta = metas.find(meta=>meta.id===item.id && meta.supportDetail)
                    if(meta){
                        return {...meta, ...item}
                    }else{
                        return null
                    }
                }else{
                    return {...item}
                }
            }).filter(item=>item!=null)
            if(!all){
                return selectItems
            }else{
                const selectIds = selectItems.map(item=>item.id)
                const leftMetas = metas.filter(meta=>selectIds.indexOf(meta.id)===-1 && meta.supportDetail)
                const leftItems = leftMetas.map(meta=>({...meta}))
                return selectItems.concat(leftItems)
            }
        },
        g_loadFormRules(selectItems){
            let rules = {}
            selectItems.forEach(item=>{
                let validator = item.validate
                if(validator && validator.type){
                    let newRules = []
                    newRules = [...Validator_Type_Detail[item.validate.type].rules(item.alias?item.alias:item.label)]
                    newRules = newRules.concat([...Validator_Type_Detail.MinMax.rules(item.alias?item.alias:item.label, validator.min,validator.max)])
                    rules[item.name] = newRules
                }
            })
            return rules
        },
        g_setupMetadataConfig(_this,metaName){
            const metadata = _this.$store.getters.metadata(metaName)
            if(!metadata){
                _this.$alert("配置加载失败")
                return ;
            }
            // console.log(metadata)
            const tableOptions = metadata.tableOptions;
            const searchOptions = metadata.searchOptions;
            const formOptions = metadata.formOptions;
            const detailOptions = metadata.detailOptions;
            if(tableOptions && tableOptions.open && _this.tableOptions){
                _this.tableOptions = {
                    ..._this.tableOptions,
                    open: true,
                    items: _this.g_loadTableItems(metadata)
                }
            }
            if(searchOptions && searchOptions.open && _this.searchOptions){
                _this.searchOptions = {
                    ..._this.searchOptions,
                    open: true,
                    items: _this.g_loadSearchItems(metadata)
                }
            }
            if(formOptions && formOptions.open && _this.editOptions){
                const formItems = _this.g_loadFormItems(metadata)
                const rules  = _this.g_loadFormRules(formItems)
                if(formItems.filter(item=>item.type === Search_Type.Province).length>0){
                    //在这里预加载province,city信息
                    _this.g_getProvinceList()
                }
                _this.editOptions = {
                    ..._this.editOptions,
                    open: true,
                    items: formItems,
                    rules: rules
                }
            }
            if(detailOptions && detailOptions.open && _this.detailOptions){
                _this.detailOptions = {
                    ..._this.detailOptions,
                    open: true,
                    items: _this.g_loadDetailItems(metadata)
                }
            }
        },
        g_setupDetailMetadataConfig(_this,metaName){
            const metadata = _this.$store.getters.metadata(metaName)
            if(!metadata){
                _this.$alert("配置加载失败")
                return ;
            }
            const detailOptions = metadata.detailOptions;
            if(detailOptions && detailOptions.open && _this.detailOptions){
                _this.detailOptions = {
                    ..._this.detailOptions,
                    open: true,
                    items: _this.g_loadDetailItems(metadata)
                }
            }
        },
        g_loadDynamicSelectData(_this){
            let needLoad = true;
            //加载动态下拉框数据
            _this.tableOptions.items.filter(item=>item.type === Search_Type.DynamicSelect).forEach(item=>{
                let dynamicUrl = item.dynamicUrl;
                if(!dynamicUrl || item.props) return;
                needLoad = false
                _this.$axios.get(dynamicUrl).then(res=>{
                    try {
                        let params = item.dynamicParams.split(',')
                        if(params.length !== 2) throw 'error parameter num'
                        item.p1 = params[0]
                        item.p2 = params[1]
                        item.props = res.data
                    }catch (e) {
                        console.log(e)
                    }
                    _this.getTableData()
                })
            })
            if(needLoad) _this.getTableData()
        },
        g_checkSpecialKey(str){
            let specialText = "";
            if (str === null || str === undefined || str === "") return specialText;
            const specialKey = `"[]%_!！~@'#￥%……&*（）：“；‘。’”+.,*-+|?？();、\\^<>{}=/$`;
            const special =
                "----|____|select|update|delete|insert|trancate|char|into|substr|ascii|declare|exec|count|master|into|drop|execute";  //某些企业的特殊要求，不需要的可删除
            const arry = special.split("|");
            for (let i = 0; i < str.length; i++) {
                if (specialKey.indexOf(str.substr(i, 1)) !== -1) {
                    specialText = str.substr(i, 1);
                    return specialText;
                }
            }
            for (let k = 0; k < arry.length; k++) {
                if (str.indexOf(arry[k]) !== -1) {
                    specialText = arry[k];
                    return specialText;
                }
            }
            return specialText;
        },
        g_specialKeyValidator(str){
            let specailText = this.g_checkSpecialKey(str);
            if(specailText){
                return `包含特殊字符'${specailText}'`
            }else{
                return true
            }
        },
        g_getParamsValueByName(params, parameterName){
            let value = null;
            if(params && params.length > 0){
                params.forEach(param=>{
                    if(param.name && param.name===parameterName){
                        value = param.value;
                    }
                })
            }
            return value;
        }
    },
})


const DELETE_INDEX = 10000;  //无效菜单索引
const UNLINK_INDEX = 20000;  //*1未关联标签索引 *2未关联页面索引
//根据树形menus转换出树形orders  menus菜单array,keys(可选)可被转换的id array
const menus2orders = (menus, keys)=>{
    let orders = [];
    menus.forEach(menu=>{
        if(menu.id >= UNLINK_INDEX) return ;
        if(keys && keys.indexOf(menu.id) === -1) return ;
        let order = {
            id: menu.id>DELETE_INDEX?(menu.id-DELETE_INDEX):menu.id,
            children: []
        };
        if(menu.children){
            order.children = menus2orders(menu.children, keys);
        }
        orders.push(order);
    })
    return orders;
}
//参考menumap将树形orders转为树形menus [可选]ignoreDelete 忽略无效菜单 ignoreDisable 忽略禁用菜单 removeMenu同时移除menumap中数据
const orders2menus = (orders, menumap, {
        ignoreDelete =false,
        ignoreDisable = false,
        removeMenu = false,
    }={})=>{
    let menus = [];
    orders.forEach(order=>{
        let menu = menumap.get(order.id);
        if(!menu){
            if(ignoreDelete) return ;
            menu = {id: (order.id+DELETE_INDEX), name: '无效菜单'}
        }else{
            if(ignoreDisable && menu.status !== 0) return ;
            if(removeMenu) menumap.delete(order.id);
        }
        if(order.children){
            menu['children'] = orders2menus(order.children, menumap, {ignoreDelete, ignoreDisable, removeMenu});
        }
        if(menu.rootOnly === 0) menu.disabled=true;
        menus.push(menu);
    })
    return menus;
}
//树形orders转换为array [可选]menuMap,onlyLeaf 配合选举出只有叶节点的orders
const orders2array = (orders, {menuMap={},onlyLeaf=false}={})=>{
    let keys = []
    orders.forEach(order=>{
        if(onlyLeaf){
            if(menuMap.get(order.id) && menuMap.get(order.id).children.length === 0){
                keys.push(order.id)
            }
        }else{
            keys.push(order.id)
        }
        if(order.children.length > 0){
            keys = keys.concat(orders2array(order.children, {menuMap, onlyLeaf}));
        }
    })
    return keys;
}
//检查菜单orders排列合理性(叶节点不能是标签,页面不能作为父节点) 返回最后一个不合理的菜单名
const checkorders = (menus)=>{
    let result = '';
    if(menus.length === 0) return result;
    menus.forEach(menu=>{
        if(menu.id >= UNLINK_INDEX) return ;
        if(menu.children.length === 0){
            if(!menu.routeComponent) result = menu.name;
        }else{
            if(menu.routeComponent){
                result = menu.name;
            }
            let rs = checkorders(menu.children);
            if(rs.length>0) result = rs;
        }
    })
    return result;
}

export {DELETE_INDEX, UNLINK_INDEX, menus2orders, orders2menus, checkorders, orders2array};