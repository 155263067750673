const Search_Type = Object.freeze({
    Input: 'input',
    Select: 'select',
    Date: 'date',
    Datetime: 'datetime',
    Daterange: 'daterange',
    Datetimerange: 'datetimerange',
    Hidden: 'hidden',
    Province: 'province',
    City: 'city',
    DynamicSelect: 'dynamicselect',
    ProvinceCity: 'provincecity',
})
const Search_Type_Label = Object.freeze({
    'input': '文本',
    'select': '下拉框',
    'date': '日期',
    'datetime': '日期时间',
    'hidden': '隐藏文本',
    'dynamicselect': '动态下拉框',
    'province': '特殊字段(省)',
    'city': '特殊字段(市)',
    'provincecity': '特殊字段(省市)',

})
const PickerOptions = Object.freeze({
    Base: {
        // disabledDate(time) {
        //     return time.getTime() > Date.now();
        // },
        shortcuts: [{
            text: '今天',
            onClick(picker) {
                picker.$emit('pick', new Date());
            }
        }, {
            text: '昨天',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24);
                picker.$emit('pick', date);
            }
        }, {
            text: '一周前',
            onClick(picker) {
                const date = new Date();
                date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', date);
            }
        }]
    },
    Range: {
        shortcuts: [{
            text: '最近一周',
            onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
            }
        }, {
            text: '最近一个月',
            onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
            }
        }, {
            text: '最近三个月',
            onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit('pick', [start, end]);
            }
        }]
    }

})



let specialText = "";
const checkSpecialKey = function(str){
    if (str === null || str === undefined || str === "") return true;
    const specialKey = `"[]%_!！~@'#￥%……&*（）：“；‘。’”+.,*-+|?？();、\\^<>{}=/$`;
    const special =
        "----|____|select|update|delete|insert|trancate|char|into|substr|ascii|declare|exec|count|master|into|drop|execute";  //某些企业的特殊要求，不需要的可删除
    const arry = special.split("|");
    for (let i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) !== -1) {
            specialText = str.substr(i, 1);
            return false;
        }
    }
    for (let k = 0; k < arry.length; k++) {
        if (str.indexOf(arry[k]) !== -1) {
            specialText = arry[k];
            return false;
        }
    }
    specialText = "";
    return true;
}

const validateSpecialKey = function(rule, value, callback){
    if (!checkSpecialKey(value)) {
        callback(new Error(`不能输入特殊字符 ${specialText}`));
    } else {
        callback();
    }
}

const Validator_Type = Object.freeze({
    NotNull: 'notNull',
    LetterNumber: 'letterNumber',
    Number: 'Number',
    Float: 'Float',
    NoSpecialSymbols: 'noSpecialSymbols',
    Email: 'email',
    Phone: 'phone',
})


const Validator_Type_Detail = Object.freeze({
    'notNull': {
        label: '非空',
        rules: function(msg){
            return [
                { required: true, message: `请输入${msg}`, trigger: 'blur'}
            ]
        }
    },
    'letterNumber': {
        label: '数字字母下划线',
        rules: function (msg) {
            return [
                { required: true, message: `请填写${msg}`, trigger: 'blur' },
                { pattern: /^\w+$/, message: `${msg}只能是数字字母下划线`, trigger: 'blur' }
            ]
        }
    },
    'Number': {
        label: '数字',
        rules: function (msg) {
            return [
                { required: true, message: `请填写${msg}`, trigger: 'blur' },
                { pattern: /^\d+$/, message: `${msg}只能是数字`, trigger: 'blur' }
            ]
        }
    },
    'Float': {
        label: '小数',
        rules: function (msg){
            return [
                { required: true, message: `请填写${msg}`, trigger: 'blur' },
                { pattern: /^[+-]?\d+(\.\d+)?$/, message: `${msg}只能是小数`, trigger: 'blur' }
            ]
        }
    },
    'noSpecialSymbols': {
        label: '无特殊字符',
        rules: function (msg) {
            return [
                { required: true, message: `请填写${msg}`, trigger: 'blur' },
                { validator: validateSpecialKey, trigger: 'blur' }
            ]
        }
    },
    'email': {
        label: '邮箱',
        rules: function (msg) {
            return [
                { required: true, type:'email', message: `${msg}不是一个有效的邮箱地址`, trigger: 'blur' },
            ]
        }
    },
    'phone': {
        label: '手机号',
        rules: function (msg) {
            return [
                { required: true, message: `请填写${msg}`, trigger: 'blur' },
                { pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/, message: `${msg}不是一个有效的手机号`, trigger: 'blur' }
            ]
        }
    },
    MinMax: {
        rules: function(msg, min ,max) {
            if(min && !max){
                return [{ min: parseInt(min), message: `${msg}长度最少为${min}个字符`, trigger: 'blur' }]
            }else if(!min && max){
                return [{ max: parseInt(max), message: `${msg}长度不超过${max}个字符`, trigger: 'blur' }]
            }else if(min && max){
                return [{ min: parseInt(min), max: parseInt(max), message: `${msg}长度在 ${min} 到 ${max} 个字符`, trigger: 'blur' }]
            }else {
                return []
            }
        }
    }

})

export {
    Search_Type,PickerOptions,Search_Type_Label,
    Validator_Type,Validator_Type_Detail
}