import Vue from 'vue'
import VueRouter from 'vue-router'
import {orders2menus} from "../global"
import store from "../store";
import axios from "../axios";


Vue.use(VueRouter);
const routes = [
    {
        path: '/equip/login',
        name: 'EquipLogin',
        component: () => import('@/views/EquipLogin'),
    },
    {
        path: '/manager/login',
        name: 'ManagerLogin',
        component: () => import('@/views/ManagerLogin'),
    },
    {
        path: '/zhiban/login',
        name: 'ZhibanLogin',
        component: () => import('@/views/ZhibanLogin'),
    },
    {
        path: '/banquan/login',
        name: 'IssuerLogin',
        component: () => import('@/views/IssuerLogin'),
    },
    {
        path: '/config/login',
        name: 'ConfigLogin',
        component: () => import('@/views/ConfigLogin'),
    },
    {
        path: '/sysversion',
        name: 'SystemVersion',
        component: () => import('@/views/SystemVersion'),
    },
]

const homeRoute = {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
    meta: {requiresAuth: true},
    children: []
}

const configRoute = [{
        path: '/config/menu',
        name: 'Tool.Menu',
        component: ()=>import('@/components/common/Tool_Menu')
}]

const logRoute = {
        path: '/hllog',
        name: 'Menu.HlLogViewer',
        component: ()=>import('@/components/common/Menu_HlLogViewer')
}

const notFoundRoute = {
    path: '*',
    component: () => import('@/views/PageNotFound')
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    let token = localStorage.getItem("dscsToken");
    let loadRoute = store.state.menu.loadRoute;
    let loginPath = ['/equip/login','/manager/login','/zhiban/login','/config/login','/banquan/login']
    if(to.path === '/sysversion'){
        next()
        return true
    }
    if(loginPath.includes(to.path)){
        if(token){
            next({path: '/'});
        }else{
            next()
        }
    }else if(!token){
        let type = localStorage.getItem("rememberType");
        let loginUrl = loginPath[0];
        if(type === '1'){
            loginUrl = loginPath[1]
        }else if(type === '2'){
            loginUrl = loginPath[0]
        }else if(type === '3'){
            loginUrl = loginPath[2]
        }else if(type === '4'){
            loginUrl = loginPath[4]
        }else if(type === '9'){
            loginUrl = loginPath[3]
        }
        localStorage.removeItem("rememberType")
        next({path: loginUrl})
    }
    else if(token && !loadRoute){
        let menumap = new Map();
        let menuorder = [];
        let menulist = [];
        // console.log("loadRoute")
        store.commit('setLoadRoute', true);
        axios.get('/sys/menu/nav').then(res => {
            menulist = res.data.menuList;
            menuorder = JSON.parse(res.data.orders);
            store.commit('setMetadatas', menulist);
            menulist.forEach(menu => {
                menumap.set(menu.id, {...menu, children:[]});
            })
            menulist = orders2menus(menuorder, menumap, {ignoreDisable: true, ignoreDelete:true});
            store.commit('setMenus', menulist);
            menulist.forEach(menu=>{
                homeRoute.children = homeRoute.children.concat(menu2routes(menu));
            })
            // console.log(menulist);
            // console.log(homeRoute);
            // console.log("newRoutes");
            if(res.data.orgType === 9){
                homeRoute.children = homeRoute.children.concat(configRoute)
            }
            router.addRoute(homeRoute);
            if(res.data.orgType === 3) {
                router.addRoute(logRoute)
            }
            router.addRoute(notFoundRoute);
        })
    }
    next();
    // if (to.matched.some(record => record.meta.requiresAuth))
})

    // 非空
    // "name": "设备批次管理",
    // "id": 6
    // "status": 0,
    // "children": [],
    // 可空
    // "routeComponent": "@/components/equipment/Menu1_Batch",
    // "routePath": "batch",
    // "routeName": "Menu1.Batch",
const menu2routes = (menu)=>{
    let routes = [];
    if(menu.routeComponent){
        let route = {
            path: menu.routePath,
            name: menu.routeName,
            component: ()=>import('@/components' + menu.routeComponent + '.vue'),
            children:[]
        }
        if(menu.children.length > 0){
            menu.children.forEach(submenu=>{
                route.children = route.children.concat(menu2routes(submenu))
            })
        }
        routes.push(route)
    }else{
        if(menu.children.length > 0){
            menu.children.forEach(submenu=>{
                routes = routes.concat(menu2routes(submenu))
            })
        }
    }
    return routes;
}

export default router