import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from "./axios";
import "./global"
// import axios from "axios";
// require('./mock.js')

Vue.config.productionTip = false
//全局修改默认配置
ElementUI.Dialog.props.closeOnClickModal.default=false;
Vue.use(ElementUI);
Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
