import Vue from 'vue'
import Vuex from 'vuex'
import { Loading } from "element-ui";
import menu from './modules/menu'
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
    // plugins: [createPersistedState({
    //     storage: window.sessionStorage,
    // })],
    state: {
        user: null,
        //初始密码
        initPass: '',
        //请求计数
        apiCount: 0,
        //loading实例
        loadingInstance: null,
        provinceList: null,
        barcodeWidth: 600,
        barcodeHeight: 600,
        shenpianCode: null,
    },
    getters: {
        user : (state)=>{
            return state.user;
        },
        initPass : (state)=>{
            return state.initPass;
        },
        provinceList:(state)=>{
            return state.provinceList;
        },
        barcodeWidth: (state)=>{
            return state.barcodeWidth;
        },
        barcodeHeight: (state)=>{
            return state.barcodeHeight;
        },
        shenpianCode: (state)=>{
            return state.shenpianCode;
        },
    },
    mutations: {
        setUser(state, user){
            state.user = user;
        },
        setInitPass(state, pass){
            state.initPass = pass;
        },
        setProvinceList(state, list){
            state.provinceList = list;
        },
        /* 打开Loading */
        startLoading(state, msg) {
            state.loadingInstance = Loading.service({
                lock: true,
                text: msg ? msg : "加载中...",
                background: "rgba(0, 0, 0, 0.7)"
            });
        },
        /* 关闭loading */
        closeLoading(state) {
            state.loadingInstance.close();
        },
        /* 更新请求线程池 */
        updateApiCount(state, handle) {
            if (handle === "add") {
                state.apiCount++;
                this.commit("startLoading");
            } else {
                state.apiCount--;
                if (state.apiCount <= 0) {
                    this.commit("closeLoading");
                }
            }
        },
        setBarcodeWidth(state, width){
            state.barcodeWidth = width;
        },
        setBarcodeHeight(state, height){
            state.barcodeHeight = height;
        },
        setShenpianCode(state, code){
            state.shenpianCode = code;
        },
    },
    actions: {
        user(content, user){
            content.commit('user', user);
        }
    },
    modules: {
        menu
    }
})
export default store
