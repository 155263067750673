<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>
<script>

  export default {
    name: 'app',
    provide(){
      return {
        reload: this.reload
      }
    },
    data(){
      return {
        isRouterAlive: true
      }
    },
    methods:{
      reload(){
        this.isRouterAlive = false;
        this.$nextTick(function () {
          this.isRouterAlive = true;
        })
      }
    },
    mounted() {
      document.title = '供片平台管理系统'
    }
  }

</script>
<style>
  /*@charset "UTF-8";*/
  /**
   *
   * @authors Your Name (you@example.org)
   * @date    2019-05-07 08:43:18
   * @version $Id: App.vue,v 1.4 2022/05/18 06:52:47 testcvs Exp $
   */
  body{
    font-size: 14px;
    margin:0;
    padding:0;
  }
  i{
    font-style:normal;
    font-size: 16px;
    line-height:42px;
  }
  .main{
    width: auto;
    margin:0 auto;
    margin-top: 50px;
    background: #e5edf7;
    height: 900px;
  }
  .main_in{
    width:1200px;
    margin:0 auto;
    /* background:#fff; */
    height:max-content;
    height: 900px;
    position: relative;
    top: -70px;
  }
  .nr{
    border:1px #ccc solid;
    padding:10px;
    box-shadow: 0 4px 3px #ccc;
    margin-top: 30px;
    width: 730px;
    background: #fff;
    min-height: 300px;
  }
  strong{
    /* border-left: 3px solid #d84e19; */
    padding-left: 3px;
    color: #666;
    font-size: 16px;
    font-weight: 100;
    float: left;
  }
  b{
    float: right;
    font-weight: 100;
  }
  i span{
    font-weight: 600;
    margin:0 5px;
  }
  a{
    text-decoration: none;
    color: #d84e19;
  }
  .right{
    float: right;
    width: max-content;
  }
  .left{
    float: left;
    width: max-content;
  }
  .nr_r{
    width:400px;
  }
  .el-header{
    padding:0;
  }
  a{
    color:#fff;
  }
  .el-submenu .el-menu-item {
    height: 50px;
    line-height: 50px;
    padding: 0 45px;
    min-width: 199px;
  }
  .el-footer{
    padding:0;
    background:#545c64;
    width: 100%;
    height:30px !important;
    line-height: 30px;
    text-align: center;
    color:#fff;
  }
  .el-breadcrumb{
    margin-bottom: 20px;
  }
  .el-pagination{
    margin-top: 20px;
  }

  /*table{*/
  /*  width: 100%;*/
  /*  border-radius: 2px;*/
  /*  margin-top: 80px;*/
  /*  clear: both;*/
  /*}*/
  /*table td, table th*/
  /*{*/
  /*  border: 1px solid #d6d6d6;*/
  /*  color: #333;*/
  /*  line-height: 40px;*/
  /*  text-align: center;*/
  /*  font-size: 14px;*/
  /*  font-weight: 100;*/
  /*}*/
  /*table th a{*/
  /*  margin: 5px;*/
  /*  background: #2e8dff;*/
  /*  padding: 5px 10px;*/
  /*  border-radius: 2px;*/
  /*  color: #dadada;*/
  /*}*/
  /*table th a:hover{*/
  /*  background: #0074ff;*/
  /*  color:#fff;*/
  /*}*/
  /*table {*/
  /*  max-width: 100%;*/
  /*  background-color: transparent;*/
  /*  border-collapse: collapse;*/
  /*  border-spacing: 0;*/
  /*}*/
  .search_in{
    width: 200px;
    float: left;
  }
  .search_button button{
    height:40px;
    border-radius:0 4px 4px 0;
  }
  .search_in input{
    border-radius:4px 0 0 4px ;
  }
  .add{
    float: left;
  }
  .search{
    /* float: right; */
    width: max-content;
  }
  .search_button{
    width: max-content;
    float: left;
  }
  iframe{
    width: 100%;
    height:800px;
  }
  /*table th .el-button{*/
  /*  padding: 8px 10px;*/
  /*}*/
  /*.el-descriptions table{*/
  /*  margin-top:0;*/
  /*  border:0;*/
  /*}*/
  .el-descriptions__header{
    margin-bottom:5px
  }
  /*.el-descriptions table td,.el-descriptions table th,.el-descriptions table tr{*/
  /*  border:0;*/
  /*}*/

  .el-descriptions{
    border-bottom:1px solid #d6d6d6 ;
    margin-bottom:10px;
  }

  .el-icon-success{
    color:#67c23a;

  }
  .el-icon-error{
    color:#f56c6c;
  }
  .xiu{
    width:max-content;
    margin: 20px auto 0;
  }
  #message_left{
    float: left;
    width: 20%;
  }
  #message_left img{
    width: 100%;
  }
  #message_right{
    float: right;
    width: 75%;
  }
  .el-descriptions-item__label{
    min-width: max-content;
  }
  .upload-demo{
    margin-bottom: 10px;
  }
  .el-upload--text{
    margin: 0 auto;
  }
  #rili{
    height: 290px;

  }
  /*.el-calendar__body table{*/
  /*  margin-top:10px ;*/
  /*}*/
  /*.el-calendar__body table td,.el-calendar__body table th{*/
  /*  line-height: 20px;*/
  /*}*/
  .el-calendar__body{
    height: 290px;
  }
  .el-calendar-table .el-calendar-day{
    height: 30px;
  }
  /*.el-calendar-table thead th{*/
  /*  line-height: 10px;*/
  /*}*/
  #shang,#xia{
    margin-top: 20px;
    clear: both;
  }
  .szt{
    float: left;
    width: 39%;
    height: 200px;
    border: 1px #e8e8e8 solid;
    margin-bottom: 10px;
  }
  #yue_xinyp,#yue_jiuyp{
    margin: 0 0.5%;
  }
  .szt h6{
    font-size: 18px;
    margin: 0;
    text-align: left;
    color: #464646;
    margin-left: 2px;
  }
  .szt ul{
    padding: 0 10px;
  }
  .szt ul li{
    list-style: none;
    padding: 5px;
    border-bottom: 1px #e8e8e8 solid;
  }
  #DKDM,#DKDM7{
    width: 20%;
  }

  /*
    add on 4.19
  */
  .table_header{
    color: #333;
    line-height: 40px !important;
    font-size: 14px !important;
    text-align: center !important;
  }
  .table_cell{
    line-height: 40px !important;
    font-size: 14px !important;
    text-align: center !important;
  }

  .el-button--blue {
    color: #FFF;
    background-color: #012EA5;
    border-color: #012EA5;
  }
  .el-button--blue-tiffany {
    color: #FFF;
    background-color: #82D8CF;
    border-color: #82D8CF;
  }
  .el-button--brown {
    color: #FFF;
    background-color: #482D1C;
    border-color: #482D1C;
  }
  .el-button--red {
    color: #FFF;
    background-color: #4C0009;
    border-color: #4C0009;
  }
  .el-button--green {
    color: #FFF;
    background-color: #01847F;
    border-color: #01847F;
  }
  .el-button--red-titian{
    color: #FFF;
    background-color: #B05923;
    border-color: #B05923;
  }
  .el-button--red-burgundy{
    color: #FFF;
    background-color: #80011C;
    border-color: #80011C;
  }
</style>